import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image"
import { Typography, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

export default function ResultsTemplate({
  data,
  pageContext
}) {
  const classes = useStyles();
  const { markdownRemark, allImageSharp, allMarkdownRemark } = data
  const { frontmatter: { title, imageAlt } } = markdownRemark

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h1">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Img fixed={allImageSharp.nodes[0].fixed} alt={imageAlt}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography>Score</Typography>
        <Typography>Challenge your friends!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Try and beat your score</Typography>
        <ul>
          {allMarkdownRemark.nodes && allMarkdownRemark.nodes.map(node => {
            return (
              <li><Link to={node.frontmatter.slug}>{node.frontmatter.month}/{node.frontmatter.year}: {node.frontmatter.title}</Link></li>
            )
          })}
        </ul>
      </Grid>
    </Grid>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $imagePath: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        month
        year
        order
        imageAlt
      }
    }
    allImageSharp(filter: {fixed: {originalName: {eq: $imagePath}}}) {
      nodes {
        fixed(width: 400, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {order: {eq: 1}}}, sort: {fields: frontmatter___year, order: DESC}) {
      nodes {
        frontmatter {
          slug
          title
          month
          year
        }
      }
    }
  }
`